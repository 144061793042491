<section class="courses-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Welcome to ecademy</span>
            <h2>Our Language Courses</h2>
            <p>Explore all of our courses and pick your suitable ones to enroll and start learning with us! We ensure that you will never regret it!</p>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6">
                <div class="single-language-courses-box">
                    <a routerLink="/courses-3-columns-style-1" class="d-block">
                        <img src="assets/img/language-courses/1.jpg" alt="image">
                    </a>
                    <div class="content">
                        <h3><a routerLink="/courses-3-columns-style-1">Chinese</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                        <a routerLink="/courses-3-columns-style-1" class="link-btn">Read More</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-language-courses-box">
                    <a routerLink="/courses-3-columns-style-1" class="d-block">
                        <img src="assets/img/language-courses/2.jpg" alt="image">
                    </a>
                    <div class="content">
                        <h3><a routerLink="/courses-3-columns-style-1">SPANISH</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                        <a routerLink="/courses-3-columns-style-1" class="link-btn">Read More</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-language-courses-box">
                    <a routerLink="/courses-3-columns-style-1" class="d-block">
                        <img src="assets/img/language-courses/3.jpg" alt="image">
                    </a>
                    <div class="content">
                        <h3><a routerLink="/courses-3-columns-style-1">JAPANESE</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                        <a routerLink="/courses-3-columns-style-1" class="link-btn">Read More</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>