<section class="language-banner-area">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="language-banner-content">
                    <h1>Learning A Language is Easier!</h1>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse.</p>
                    <a routerLink="/register" class="default-btn">
                        <i class='bx bx-user-circle icon-arrow before'></i>
                        <span class="label">
                            Join For Free
                        </span>
                        <i class="bx bx-user-circle icon-arrow after"></i>
                    </a>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="language-banner-image">
                    <img src="assets/img/banner-img4.png" alt="image">
                </div>
            </div>
        </div>
    </div>
    <div class="lang-shape1"><img src="assets/img/lang-shape1.png" alt="image"></div>
</section>