import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-homeeight-events',
  templateUrl: './homeeight-events.component.html',
  styleUrls: ['./homeeight-events.component.scss']
})
export class HomeeightEventsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
