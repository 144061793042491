<div class="motivation-featured-area bg-with-black-color">
    <div class="container-fluid">
        <div class="row justify-content-center">
            <div class="col-lg-3 col-sm-6">
                <div class="motivation-featured-item">
                    <div class="icon">
                        <i class="flaticon-checkmark"></i>
                    </div>
                    <h3>
                        <a routerLink="/single-courses">Learn The Latest Skills</a>
                    </h3>
                    <p>Like business analytics, graphic design, Python, and more.</p>
                    <a routerLink="/single-courses" class="featured-btn">View Courses +</a>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div class="motivation-featured-item">
                    <div class="icon">
                        <i class="flaticon-login"></i>
                    </div>
                    <h3>
                        <a routerLink="/single-courses">100k Online Courses</a>
                    </h3>
                    <p>Like business analytics, graphic design, Python, and more.</p>
                    <a routerLink="/single-courses" class="featured-btn">View Courses +</a>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div class="motivation-featured-item">
                    <div class="icon">
                        <i class="flaticon-credit-card"></i>
                    </div>
                    <h3>
                        <a routerLink="/single-courses">Earn A Certificate</a>
                    </h3>
                    <p>Like business analytics, graphic design, Python, and more.</p>
                    <a routerLink="/single-courses" class="featured-btn">View Courses +</a>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div class="motivation-featured-item">
                    <div class="icon">
                        <i class="flaticon-verify"></i>
                    </div>
                    <h3>
                        <a routerLink="/single-courses">Up Your Skill</a>
                    </h3>
                    <p>With on-demand training and development programs.</p>
                    <a routerLink="/single-courses" class="featured-btn">View Courses +</a>
                </div>
            </div>
        </div>
    </div>
</div>