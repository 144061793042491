import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hometwelve-popular-courses',
  templateUrl: './hometwelve-popular-courses.component.html',
  styleUrls: ['./hometwelve-popular-courses.component.scss']
})
export class HometwelvePopularCoursesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
