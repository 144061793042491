<div class="motivation-mission-area">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="motivation-mission-content">
                    <span class="sub-title">DISCOVER MISSION</span>
                    <h3>Why Our Platform is Better</h3>
                    <div class="mission-inner-box">
                        <div class="number">01</div>
                        <h4>Quality can be better than quantity in this platform</h4>
                        <p>Pellentesque in ipsum id orci porta dapibus. Praesent sapien massa, convallis a pellentesque nec, egestas non nisi. Proin eget tortor risus. Vivamus suscipit tortor eget felis porttitor volutpat. Praesent sapien massa, convallis a pellentesque nec, egestas non nisi. Curabitur arcu erat, accumsan id imperdiet et, porttitor at sem.</p>
                    </div>
                    <div class="mission-inner-box">
                        <div class="number">02</div>
                        <h4>A place where you can achieve</h4>
                        <p>Sed porttitor lectus nibh. Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus. Cras ultricies ligula sed magna dictum porta. Curabitur arcu erat, accumsan id imperdiet et, porttitor at sem.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="motivation-mission-image"></div>
            </div>
        </div>
    </div>
</div>