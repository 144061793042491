import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home-page-nine',
  templateUrl: './home-page-nine.component.html',
  styleUrls: ['./home-page-nine.component.scss']
})
export class HomePageNineComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
