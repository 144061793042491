<section class="courses-area ptb-100 bg-dark bg-0b0517">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Learn At Your Own Pace</span>
            <h2>Top Selling Courses</h2>
            <p>Explore all of our courses and pick your suitable ones to enroll and start learning with us! We ensure that you will never regret it!</p>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6">
                <div class="courses-box">
                    <div class="courses-image">
                        <a routerLink="/single-courses" class="d-block image">
                            <img src="assets/img/courses/12.jpg" alt="image">
                        </a>
                        <a routerLink="/gym-coaching" class="fav"><i class='bx bx-heart'></i></a>
                        <div class="price shadow">$39</div>
                    </div>
                    <div class="courses-content">
                        <div class="course-author d-flex align-items-center">
                            <img src="assets/img/user1.jpg" class="rounded-circle" alt="image">
                            <span>Alex Morgan</span>
                        </div>
                        <h3><a routerLink="/single-courses">Business Fundamentals: Business Strategy Plan</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                        <ul class="courses-box-footer d-flex justify-content-between align-items-center">
                            <li><i class='bx bx-book-bookmark'></i> 15 Lessons</li>
                            <li><i class='bx bx-group'></i> 145 Students</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="courses-box">
                    <div class="courses-image">
                        <a routerLink="/single-courses" class="d-block image">
                            <img src="assets/img/courses/13.jpg" alt="image">
                        </a>
                        <a routerLink="/gym-coaching" class="fav"><i class='bx bx-heart'></i></a>
                        <div class="price shadow">$49</div>
                    </div>
                    <div class="courses-content">
                        <div class="course-author d-flex align-items-center">
                            <img src="assets/img/user2.jpg" class="rounded-circle" alt="image">
                            <span>Sarah Taylor</span>
                        </div>
                        <h3><a routerLink="/single-courses">Business Model Grow Your Company </a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                        <ul class="courses-box-footer d-flex justify-content-between align-items-center">
                            <li><i class='bx bx-book-bookmark'></i> 20 Lessons</li>
                            <li><i class='bx bx-group'></i> 100 Students</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="courses-box">
                    <div class="courses-image">
                        <a routerLink="/single-courses" class="d-block image">
                            <img src="assets/img/courses/14.jpg" alt="image">
                        </a>
                        <a routerLink="/gym-coaching" class="fav"><i class='bx bx-heart'></i></a>
                        <div class="price shadow">$59</div>
                    </div>
                    <div class="courses-content">
                        <div class="course-author d-flex align-items-center">
                            <img src="assets/img/user3.jpg" class="rounded-circle" alt="image">
                            <span>David Warner</span>
                        </div>
                        <h3><a routerLink="/single-courses">Master Cognitive Improve Your Thinking</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                        <ul class="courses-box-footer d-flex justify-content-between align-items-center">
                            <li><i class='bx bx-book-bookmark'></i> 22 Lessons</li>
                            <li><i class='bx bx-group'></i> 150 Students</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-12 col-md-12">
                <div class="courses-info">
                    <p>Enjoy the top notch learning methods and achieve next level skills! You are the creator of your own career & we will guide you through that. <a routerLink="/register">Register Free Now!</a>.</p>
                </div>
            </div>
        </div>
    </div>
</section>