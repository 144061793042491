<section class="motivation-courses-area bg-FAF8F8 pt-100 pb-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">POPULAR COURSES</span>
            <h2>Our Best Online Courses</h2>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6">
                <div class="single-motivation-courses-box mb-30">
                    <div class="courses-image">
                        <a routerLink="/single-courses" class="d-block"><img src="assets/img/motivation-course/courses/courses-1.jpg" alt="image"></a>
                        <div class="price">$188</div>
                    </div>
                    <div class="courses-content">
                        <div class="courses-tag">
                            <a routerLink="/" class="d-block">DESIGN</a>
                        </div>
                        <div class="course-author d-flex align-items-center justify-content-between">
                            <div class="author-box">
                                <img src="assets/img/user1.jpg" class="rounded-circle mr-2" alt="image">
                                <span>Margaret James</span>
                            </div>
                            <div class="courses-rating">
                                <div class="review-stars-rated">
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                </div>
                                <div class="rating-total">(0 Rating)</div>
                            </div>
                        </div>
                        <h3><a routerLink="/single-courses" class="d-inline-block">Information About UI/UX Design Degree</a></h3>
                    </div>
                    <div class="courses-box-footer">
                        <ul>
                            <li class="students-number">
                                <i class='bx bx-user'></i> 4 students
                            </li>
                            <li class="courses-lesson">
                                <i class='bx bx-book-open'></i> 10 lessons
                            </li>
                            <li class="courses-time">
                                <i class='bx bx-time-five'></i> 1 Hours
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-motivation-courses-box mb-30">
                    <div class="courses-image">
                        <a routerLink="/single-courses" class="d-block"><img src="assets/img/motivation-course/courses/courses-2.jpg" alt="image"></a>
                        <div class="price">$166</div>
                    </div>
                    <div class="courses-content">
                        <div class="courses-tag">
                            <a routerLink="/" class="d-block">BUSINESS</a>
                        </div>
                        <div class="course-author d-flex align-items-center justify-content-between">
                            <div class="author-box">
                                <img src="assets/img/user2.jpg" class="rounded-circle mr-2" alt="image">
                                <span>Kimberly Joseph</span>
                            </div>
                            <div class="courses-rating">
                                <div class="review-stars-rated">
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                </div>
                                <div class="rating-total">(0 Rating)</div>
                            </div>
                        </div>
                        <h3><a routerLink="/single-courses" class="d-inline-block">React – The Complete Guide (React, Router, Redux)</a></h3>
                    </div>
                    <div class="courses-box-footer">
                        <ul>
                            <li class="students-number">
                                <i class='bx bx-user'></i> 9 students
                            </li>
                            <li class="courses-lesson">
                                <i class='bx bx-book-open'></i> 7 lessons
                            </li>
                            <li class="courses-time">
                                <i class='bx bx-time-five'></i> 1 Hours
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-motivation-courses-box mb-30">
                    <div class="courses-image">
                        <a routerLink="/single-courses" class="d-block"><img src="assets/img/motivation-course/courses/courses-3.jpg" alt="image"></a>
                        <div class="price">$166</div>
                    </div>
                    <div class="courses-content">
                        <div class="courses-tag">
                            <a routerLink="/" class="d-block">DEVELOPMENT</a>
                        </div>
                        <div class="course-author d-flex align-items-center justify-content-between">
                            <div class="author-box">
                                <img src="assets/img/user3.jpg" class="rounded-circle mr-2" alt="image">
                                <span>Dennis Hines</span>
                            </div>
                            <div class="courses-rating">
                                <div class="review-stars-rated">
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                </div>
                                <div class="rating-total">(0 Rating)</div>
                            </div>
                        </div>
                        <h3><a routerLink="/single-courses" class="d-inline-block">Certified JavaScript with Free Project Course</a></h3>
                    </div>
                    <div class="courses-box-footer">
                        <ul>
                            <li class="students-number">
                                <i class='bx bx-user'></i> 12 students
                            </li>
                            <li class="courses-lesson">
                                <i class='bx bx-book-open'></i> 8 lessons
                            </li>
                            <li class="courses-time">
                                <i class='bx bx-time-five'></i> 1 Hours
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-motivation-courses-box mb-30">
                    <div class="courses-image">
                        <a routerLink="/single-courses" class="d-block"><img src="assets/img/motivation-course/courses/courses-8.jpg" alt="image"></a>
                        <div class="price">$120</div>
                    </div>
                    <div class="courses-content">
                        <div class="courses-tag">
                            <a routerLink="/" class="d-block">BUSINESS COACHING</a>
                        </div>
                        <div class="course-author d-flex align-items-center justify-content-between">
                            <div class="author-box">
                                <img src="assets/img/user4.jpg" class="rounded-circle mr-2" alt="image">
                                <span>Darcy Martin</span>
                            </div>
                            <div class="courses-rating">
                                <div class="review-stars-rated">
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                </div>
                                <div class="rating-total">(0 Rating)</div>
                            </div>
                        </div>
                        <h3><a routerLink="/single-courses" class="d-inline-block">Internationally Accredited Diploma Certificate</a></h3>
                    </div>
                    <div class="courses-box-footer">
                        <ul>
                            <li class="students-number">
                                <i class='bx bx-user'></i> 7 students
                            </li>
                            <li class="courses-lesson">
                                <i class='bx bx-book-open'></i> 5 lessons
                            </li>
                            <li class="courses-time">
                                <i class='bx bx-time-five'></i> 1 Hours
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-motivation-courses-box mb-30">
                    <div class="courses-image">
                        <a routerLink="/single-courses" class="d-block"><img src="assets/img/motivation-course/courses/courses-9.jpg" alt="image"></a>
                        <div class="price">$100</div>
                    </div>
                    <div class="courses-content">
                        <div class="courses-tag">
                            <a routerLink="/" class="d-block">COACHING</a>
                        </div>
                        <div class="course-author d-flex align-items-center justify-content-between">
                            <div class="author-box">
                                <img src="assets/img/user5.jpg" class="rounded-circle mr-2" alt="image">
                                <span>Earl Ortiz</span>
                            </div>
                            <div class="courses-rating">
                                <div class="review-stars-rated">
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                </div>
                                <div class="rating-total">(0 Rating)</div>
                            </div>
                        </div>
                        <h3><a routerLink="/single-courses" class="d-inline-block">Nutrition Certification Diet & Meal Panning</a></h3>
                    </div>
                    <div class="courses-box-footer">
                        <ul>
                            <li class="students-number">
                                <i class='bx bx-user'></i> 10 students
                            </li>
                            <li class="courses-lesson">
                                <i class='bx bx-book-open'></i> 3 lessons
                            </li>
                            <li class="courses-time">
                                <i class='bx bx-time-five'></i> 1 Hours
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-motivation-courses-box mb-30">
                    <div class="courses-image">
                        <a routerLink="/single-courses" class="d-block"><img src="assets/img/motivation-course/courses/courses-10.jpg" alt="image"></a>
                        <div class="price">$85</div>
                    </div>
                    <div class="courses-content">
                        <div class="courses-tag">
                            <a routerLink="/" class="d-block">DESIGN</a>
                        </div>
                        <div class="course-author d-flex align-items-center justify-content-between">
                            <div class="author-box">
                                <img src="assets/img/user6.jpg" class="rounded-circle mr-2" alt="image">
                                <span>Wilbur Owens</span>
                            </div>
                            <div class="courses-rating">
                                <div class="review-stars-rated">
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                </div>
                                <div class="rating-total">(0 Rating)</div>
                            </div>
                        </div>
                        <h3><a routerLink="/single-courses" class="d-inline-block">Master Cognitive Improve Your Thinking</a></h3>
                    </div>
                    <div class="courses-box-footer">
                        <ul>
                            <li class="students-number">
                                <i class='bx bx-user'></i> 20 students
                            </li>
                            <li class="courses-lesson">
                                <i class='bx bx-book-open'></i> 10 lessons
                            </li>
                            <li class="courses-time">
                                <i class='bx bx-time-five'></i> 1 Hours
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-12 col-md-12">
                <div class="courses-info-wrap">
                    <p>1500+ Courses Availabel <a routerLink="/courses-3-columns-style-1">View all Courses</a></p>
                </div>
            </div>
        </div>
    </div>
</section>