<section class="blog-area pt-100 pb-70">
    <div class="container">
        <div class="section-title text-start">
            <span class="sub-title">Explore News</span>
            <h2>Our Latest Insights</h2>
            <a routerLink="/blog-style-1" class="default-btn"><i class='bx bx-book-reader icon-arrow before'></i><span class="label">Read All</span><i class="bx bx-book-reader icon-arrow after"></i></a>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post-card mb-30">
                    <div class="post-image">
                        <a routerLink="/single-blog" class="d-block">
                            <img src="assets/img/motivation-course/blog/blog-1.jpg" alt="image">
                        </a>
                    </div>
                    <div class="post-content">
                        <div class="tag">
                            <a routerLink="/blog-style-one">EDUCATION</a>
                        </div>
                        <ul class="post-meta">
                            <li class="post-author">
                                <img src="assets/img/user1.jpg" class="d-inline-block rounded-circle mr-2" alt="image">
                                By: <a routerLink="/blog-style-1" class="d-inline-block">Steven Smith</a>
                            </li>
                            <li class="date"><i class='bx bxs-calendar'></i> 01/25/2023</li>
                        </ul>
                        <h3><a routerLink="/single-blog" class="d-inline-block">Global education: ideas for the way move forward</a></h3>
                        <a routerLink="/single-blog" class="read-more-btn">Read More</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post-card mb-30">
                    <div class="post-image">
                        <a routerLink="/single-blog" class="d-block">
                            <img src="assets/img/motivation-course/blog/blog-2.jpg" alt="image">
                        </a>
                    </div>
                    <div class="post-content">
                        <div class="tag">
                            <a routerLink="/blog-style-one">EDUCATION</a>
                        </div>
                        <ul class="post-meta">
                            <li class="post-author">
                                <img src="assets/img/user2.jpg" class="d-inline-block rounded-circle mr-2" alt="image">
                                By: <a routerLink="/blog-style-1" class="d-inline-block">Justin Vance</a>
                            </li>
                            <li class="date"><i class='bx bxs-calendar'></i> 01/25/2023</li>
                        </ul>
                        <h3><a routerLink="/single-blog" class="d-inline-block">New report reimagines the broader education workforce</a></h3>
                        <a routerLink="/single-blog" class="read-more-btn">Read More</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post-card mb-30">
                    <div class="post-image">
                        <a routerLink="/single-blog" class="d-block">
                            <img src="assets/img/motivation-course/blog/blog-3.jpg" alt="image">
                        </a>
                    </div>
                    <div class="post-content">
                        <div class="tag">
                            <a routerLink="/blog-style-one">EDUCATION</a>
                        </div>
                        <ul class="post-meta">
                            <li class="post-author">
                                <img src="assets/img/user3.jpg" class="d-inline-block rounded-circle mr-2" alt="image">
                                By: <a routerLink="/blog-style-1" class="d-inline-block">William Gerard</a>
                            </li>
                            <li class="date"><i class='bx bxs-calendar'></i> 01/25/2023</li>
                        </ul>
                        <h3><a routerLink="/single-blog" class="d-inline-block">What’s going on in this picture? | Jan. 13, 2022</a></h3>
                        <a routerLink="/single-blog" class="read-more-btn">Read More</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>