<section class="subscribe-area ptb-100">
    <div class="container">
        <div class="subscribe-content">
            <span class="sub-title">Go At Your Own Pace</span>
            <h2 class="playfair-display">Subscribe To Our Newsletter</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
            <form class="newsletter-form">
                <input type="text" class="input-newsletter" placeholder="Enter your email address" name="EMAIL">
                <button type="submit" class="default-btn"><i class='bx bx-paper-plane icon-arrow before'></i><span class="label">Subscribe Now</span><i class="bx bx-paper-plane icon-arrow after"></i></button>
            </form>
        </div>
    </div>
    <div class="health-coaching-shape8"><img src="assets/img/health-coaching/health-coaching-shape8.png" alt="image"></div>
    <div class="health-coaching-shape9"><img src="assets/img/health-coaching/health-coaching-shape9.png" alt="image"></div>
</section>