<section class="courses-area pb-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Learn At Your Own Pace</span>
            <h2 class="playfair-display">Top Selling Courses</h2>
            <p>Explore all of our courses and pick your suitable ones to enroll and start learning with us! We ensure that you will never regret it!</p>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6">
                <div class="single-courses-box-item">
                    <div class="courses-image">
                        <a routerLink="/single-courses" class="d-block image">
                            <img src="assets/img/health-coaching/courses-img1.jpg" alt="image">
                        </a>
                        <a routerLink="/" class="fav"><i class='bx bx-heart'></i></a>
                        <div class="price shadow">$39</div>
                    </div>
                    <div class="courses-content">
                        <div class="course-author d-flex align-items-center">
                            <img src="assets/img/user1.jpg" class="rounded-circle" alt="image">
                            <span>Alex Morgan</span>
                        </div>
                        <h3 class="playfair-display"><a routerLink="/single-courses">Diet And Nutrition Coach Certification</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-courses-box-item">
                    <div class="courses-image">
                        <a routerLink="/single-courses" class="d-block image">
                            <img src="assets/img/health-coaching/courses-img2.jpg" alt="image">
                        </a>
                        <a routerLink="/" class="fav"><i class='bx bx-heart'></i></a>
                        <div class="price shadow">$49</div>
                    </div>
                    <div class="courses-content">
                        <div class="course-author d-flex align-items-center">
                            <img src="assets/img/user2.jpg" class="rounded-circle" alt="image">
                            <span>Sarah Taylor</span>
                        </div>
                        <h3 class="playfair-display"><a routerLink="/single-courses">Internationally Accredited Diploma Certificate</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-courses-box-item">
                    <div class="courses-image">
                        <a routerLink="/single-courses" class="d-block image">
                            <img src="assets/img/health-coaching/courses-img3.jpg" alt="image">
                        </a>
                        <a routerLink="/" class="fav"><i class='bx bx-heart'></i></a>
                        <div class="price shadow">$59</div>
                    </div>
                    <div class="courses-content">
                        <div class="course-author d-flex align-items-center">
                            <img src="assets/img/user3.jpg" class="rounded-circle" alt="image">
                            <span>David Warner</span>
                        </div>
                        <h3 class="playfair-display"><a routerLink="/single-courses">Nutrition Certification Diet & Meal Panning</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-12 col-md-12">
                <div class="courses-info">
                    <p>Enjoy the top notch learning methods and achieve next level skills! You are the creator of your own career & we will guide you through that. <a routerLink="/register">Register Free Now!</a>.</p>
                </div>
            </div>
        </div>
    </div>
</section>