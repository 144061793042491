<section class="free-trial-area">
    <div class="container">
        <div class="free-trial-form">
            <span class="sub-title">Free Trial</span>
            <h2>Sign Up For A Free Trial</h2>
            <form>
                <div class="form-group">
                    <input type="text" class="form-control" placeholder="Your Name *">
                </div>
                <div class="form-group">
                    <input type="email" class="form-control" placeholder="Your Email *">
                </div>
                <div class="form-group">
                    <input type="text" class="form-control" placeholder="Your Phone *">
                </div>
                <div class="form-group">
                    <input type="text" class="form-control" placeholder="Your Subject *">
                </div>
                <button type="submit">Register Now</button>
            </form>
        </div>
    </div>
    <div class="lang-shape3"><img src="assets/img/lang-shape3.png" alt="image"></div>
</section>