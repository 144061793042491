import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-language-category',
  templateUrl: './language-category.component.html',
  styleUrls: ['./language-category.component.scss']
})
export class LanguageCategoryComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
