<div class="start-free-trial-area pb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="start-free-trial-content">
                    <h3>Start Free Trial</h3>
                    <span>Already have an ID? <a routerLink="/login">Sign In</a></span>
                    <form class="start-free-trial-form">
                        <div class="row justify-content-center">
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label>Your Name</label>
                                    <input type="text" class="form-control">
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label>Email</label>
                                    <input type="email" class="form-control">
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label>Phone</label>
                                    <input type="number" class="form-control">
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label>Password</label>
                                    <input type="password" class="form-control">
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label>Country</label>
                                    <input type="text" class="form-control">
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label>Organization</label>
                                    <input type="text" class="form-control">
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-check">
                                    <input type="checkbox" class="form-check-input" id="Check-1">
                                    <label class="form-check-label" for="Check-1">By clicking, you are agreeing to our applicable <a routerLink="/contact">terms</a> and <a routerLink="/contact">privacy policy</a></label>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="free-btn-box">
                                    <button type="submit">Free Trial</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="start-free-trial-image">
                    <img src="assets/img/motivation-course/free-trial.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</div>